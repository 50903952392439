import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Hero } from '../../components/site-only/hero'
import {
  Flex,
  Container,
  Text,
  Grid,
  GridItem,
  Box,
  Center,
  Divider,
  Button,
  useToken,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  Section,
  SectionHeading,
  SectionContent,
} from '../../components/section'
import {
  HeadingGroup,
  HeadingSubtitle,
  HeadingTitle,
} from '../../components/heading-group'
import { Filter } from '../../components/filter'
import { Image } from '../../components/site-only/image'
import { Link } from '../../components/site-only/link'
import { Icon } from '../../components/icon'
import { Carousel } from '../../components/carousel'

// @ts-ignore
import HeroImage from '../../images/pr_performance_hero.jpg'
import { useState } from 'react'
import { SlideFadeInView } from '../../components/slide-fade-in-view'
import { Wrapper, WrapperFluid } from '../../components/site-only/wrapper'

export default function PRPerformance({
  data: { prPerformance },
}: PageProps<GatsbyTypes.PRPerformancePageQuery>) {
  const breakpoint = useToken('breakpoints', 'md')
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoint})`)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const section = prPerformance?.section

  return (
    <>
      {section && (
        <>
          <Hero
            bgImage={`url(${HeroImage})`}
            bgSize={'cover'}
            bgPos={'bottom'}
            pt={90}
            h={{
              base: 300,
              md: 450,
            }}
          >
            <Filter
              type={'opacity'}
              value={0.3}
              bg={'black'}
              position={'absolute'}
              top={0}
              left={0}
              w={'full'}
              h={'full'}
            />
            <Container
              position={'relative'}
              textAlign={{
                base: 'center',
                md: 'unset',
              }}
            >
              <SlideFadeInView>
                <Text
                  display={'inline-block'}
                  textStyle={'lg'}
                  borderBottom={4}
                  pb={2}
                  mb={20}
                  zIndex={1}
                >
                  Service
                </Text>
                <Section>
                  <SectionHeading as={HeadingGroup}>
                    <HeadingTitle>{section.heading?.value}</HeadingTitle>
                    <HeadingSubtitle>
                      {section.subtitle?.element?.value}
                    </HeadingSubtitle>
                  </SectionHeading>
                  <SectionContent
                    dangerouslySetInnerHTML={{
                      __html: section.contents[0]?.value ?? '',
                    }}
                  />
                </Section>
              </SlideFadeInView>
            </Container>
          </Hero>

          <Flex
            layerStyle={'secondary'}
            textStyle={'md-bold'}
            position={'relative'}
            overflow={'hidden'}
            justifyContent={'center'}
            alignItems={'center'}
            h={94}
          >
            <SlideFadeInView>
              <Text
                textAlign={'center'}
                dangerouslySetInnerHTML={{
                  __html: section.contents[1]?.value ?? '',
                }}
              />
            </SlideFadeInView>

            <Text
              position={'absolute'}
              bottom={{
                base: 0,
                md: -12,
              }}
              fontSize={'12vw'}
              opacity={0.1}
              lineHeight={1}
              whiteSpace={'nowrap'}
            >
              {section.subtitle?.element?.value}
            </Text>
          </Flex>

          <Wrapper invert>
            <Container>
              <SlideFadeInView>
                {isDesktop ? (
                  <Grid
                    templateColumns={'repeat(3, 1fr)'}
                    templateRows={'repeat(4, auto)'}
                    autoFlow={'column'}
                    alignItems={'center'}
                    rowGap={16}
                    columnGap={8}
                    textAlign={'center'}
                  >
                    {section.list?.listItem.map((item, index) => {
                      const heading = item.contents[0]?.value
                      const image = item.contents[1]?.image?.properties
                      const text = item.contents[2]?.value

                      return (
                        <GridItem key={item.key} display={'contents'}>
                          <GridItem>
                            <Text
                              display={'inline-block'}
                              textStyle={'xl-bold'}
                              color={'primary.500'}
                              borderBottom={6}
                              borderBottomColor={'secondary.500'}
                              px={10}
                              pb={2}
                            >
                              {String.fromCharCode(65 + index)}
                            </Text>
                          </GridItem>
                          <GridItem
                            as={'p'}
                            dangerouslySetInnerHTML={{
                              __html: heading ?? '',
                            }}
                          />
                          <GridItem>
                            <Image alt={image?.alt} {...image?.src} />
                          </GridItem>
                          <GridItem
                            as={'p'}
                            dangerouslySetInnerHTML={{
                              __html: text ?? '',
                            }}
                          />
                        </GridItem>
                      )
                    })}
                  </Grid>
                ) : (
                  <WrapperFluid>
                    <Grid gap={16} textAlign={'center'}>
                      {section.list?.listItem
                        .filter((_, index) => index === activeIndex)
                        .map(item => {
                          const heading = item.contents[0]?.value
                          const text = item.contents[2]?.value

                          return (
                            <Box key={item.key} display={'contents'}>
                              <GridItem textAlign={'center'}>
                                <Text
                                  display={'inline-block'}
                                  textStyle={'xl-bold'}
                                  color={'primary.500'}
                                  borderBottom={6}
                                  borderBottomColor={'secondary.500'}
                                  px={10}
                                  pb={2}
                                >
                                  {String.fromCharCode(65 + activeIndex)}
                                </Text>
                              </GridItem>
                              <GridItem
                                as={'p'}
                                dangerouslySetInnerHTML={{
                                  __html: heading ?? '',
                                }}
                              />
                              <GridItem
                                as={'p'}
                                dangerouslySetInnerHTML={{
                                  __html: text ?? '',
                                }}
                                order={1}
                              />
                            </Box>
                          )
                        })}
                      <GridItem
                        w={{
                          base: '100vw',
                          md: 'unset',
                        }}
                      >
                        <Carousel
                          arrows={false}
                          centerMode={true}
                          centerPadding={'10%'}
                          beforeChange={(_, next) => {
                            setActiveIndex(next)
                          }}
                        >
                          {section.list?.listItem.map(item => {
                            const image = item.contents[1]?.image?.properties

                            return (
                              <Image
                                key={item.key}
                                alt={image?.alt}
                                {...image?.src}
                              />
                            )
                          })}
                        </Carousel>
                      </GridItem>
                    </Grid>
                  </WrapperFluid>
                )}

                <Center mt={23}>
                  <Divider orientation={'vertical'} h={40} />
                </Center>

                <Box textAlign={'center'} mt={23}>
                  <Button
                    as={Link}
                    href={section.contents[2]?.link?.properties.href}
                    variant={'rounded.primary'}
                    size={'md'}
                  >
                    <Icon kind={'email'} mr={5} />
                    {section.contents[2]?.link?.value}
                  </Button>
                </Box>
              </SlideFadeInView>
            </Container>
          </Wrapper>
        </>
      )}
    </>
  )
}

export const query = graphql`
  query PRPerformancePage {
    prPerformance: markdownRemark(
      frontmatter: { title: { eq: "PR Performance" } }
    ) {
      childrenMarkdownElement {
        childrenMarkdownElement {
          childrenMarkdownElement {
            childrenMarkdownElement {
              childrenMarkdownElement {
                __typename
              }
            }
          }
        }
      }
      section: childElement(filter: { type: { eq: "section" } }) {
        tagName
        ...MarkdownHeading
        ...MarkdownSubtitle
        contents: childrenElement(
          filter: {
            type: { eq: "paragraph" }
            children: { type: { ne: "strong" } }
          }
        ) {
          key: id
          tagName
          value
          ...MarkdownLink
        }
        list: childElement(filter: { type: { eq: "list" } }) {
          tagName
          listItem: childrenElement(filter: { type: { eq: "listItem" } }) {
            key: id
            contents: childrenElement(filter: { type: { eq: "paragraph" } }) {
              key: id
              tagName
              value
              ...MarkdownLink
              ...MarkdownImage
            }
          }
        }
      }
    }
  }
`
